<script>
import { useMeta } from 'vue-meta'
import Menu from '@/components/Edelweiss-Q2/Menu'

export default {
	name: "EdelweissQ2_list",
	data() {
		return {
		};
	},
	setup () {
		useMeta(
		{ 
			title: '艾德懷斯啤酒頂級奢華體驗',
			description: '2024/3/15-5/15至指定通路，購買艾德懷斯啤酒全系列，輸入序號就抽洲際住宿!!!' 
		})		
	},
	components: {
		Menu
	},
	computed: {
	},
	watch: {
	},
	mounted() {
	},
	methods: {
	},
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
